import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, Button, Link, Snackbar, Divider, Typography, IconButton, Tooltip } from '@material-ui/core';
import Title from './Title';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isBlank } from './validacao'
import { url, getToken } from './_helpers'
import DeleteIcon from '@material-ui/icons/Delete';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function VendaAdd() {
    let { id, visita } = useParams();
    const [error, setError] = useState({});
    const [redirecionar, setRedirecionar] = useState(false);
    const [selecionado, setSelecionado] = useState();
    const [selecionadoService, setSelecionadoService] = useState();
    const [infoAdd, setinfoAdd] = useState('');
    const [idSalvo, setIdSalvo] = useState(0);
    const [options, setOptions] = useState([]);
    const [optionsService, setOptionsService] = useState([]);
    const [values, setValues] = useState({
        codigo: '',
        frete: 0,
        desconto: 0,
        pagamento: 'DINHEIRO',
        total: 0,
        data: new Date(),
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        obs: '',
        cliente: {
            id: 0,
            nome: '',
            cpfCnpj: '',
            celular: '',
            telefone: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: ''
        },
        produtos: [],
        servicos: [],
        informacoes: [],
        medidas: []

    });
    const [medida, setMedida] = useState({
        ambiente: '',
        metragem: ''
    })
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeMetragem = (prop) => (event) => {
        setMedida({ ...medida, [prop]: event.target.value })
    };

    const handleChange = (prop) => (event) => {
        let total = values.servicos.reduce((acumulador, numero, indice, original) => {
            return acumulador += Number(numero.total);
        }, 0)
        if (prop === 'frete')
            total = (event.target.value - values.desconto + total).toFixed(2)
        if (prop === 'desconto')
            total = (values.frete - event.target.value + total).toFixed(2)
        setValues({ ...values, [prop]: event.target.value, total });
    };

    const handleChangeProd = (index, prop) => (event) => {
        values.produtos[index][prop] = event.target.value
        setValues({ ...values, produtos: values.produtos });
    };

    const handleChangeService = (index, prop) => (event) => {
        values.servicos[index][prop] = event.target.value
        const totalS = values.servicos.reduce((acumulador, numero, indice, original) => {
            return acumulador += Number(numero.total);
        }, 0)
        setValues({ ...values, servicos: values.servicos, total: (totalS + Number(values.frete) - Number(values.desconto)).toFixed(2) });

    };

    const handleChangeAuto = (item) => {
        setSelecionado(item)
        return true
    };

    const handleChangeAutoService = (item) => {
        setSelecionadoService(item)
        return true
    };

    const handleAddProduto = () => {
        if (selecionado) {
            values.produtos.unshift({ ...selecionado, total: selecionado.preco, desconto: 0, qnt: 1, id: 0 })
            setValues({ ...values, produtos: values.produtos })
            setSelecionado(null)
        }
    }

    const handleAddServico = () => {
        if (selecionadoService) {
            values.servicos.unshift({ ...selecionadoService, total: Number(selecionadoService.preco), id: 0 })
            const totalS = values.servicos.reduce((acumulador, numero, indice, original) => {
                return acumulador += Number(numero.total);
            }, 0)
            setValues({ ...values, ['servicos']: values.servicos, total: (totalS + Number(values.frete) - Number(values.desconto)).toFixed(2) })
            setSelecionadoService(null)
        }
    }

    const handleChangeCliente = (prop) => (event) => {
        setValues({ ...values, ['cliente']: { ...values.cliente, [prop]: event.target.value } });
    };


    const handleCepClienteChange = (event) => {
        const cep = event.target.value
        console.log(`https://viacep.com.br/ws/${cep}/json`)
        fetch(`https://viacep.com.br/ws/${cep}/json`)
            .then(res => res.json())
            .then(
                (result) => {
                    setValues({ ...values, ['cliente']: { ...values.cliente, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep } });
                },
                (error) => {
                    console.log(error)
                }
            )
        setValues({ ...values, ['cliente']: { ...values.cliente, ['cep']: cep, ['endereco']: '' } });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInfoAdd = (event) => {
        setinfoAdd(event.target.value)
    }

    const handleDateChange = (date) => {
        setValues({ ...values, ['data']: date });
    };

    const handleCepChange = (event) => {
        const cep = event.target.value
        console.log(`https://viacep.com.br/ws/${cep}/json`)
        fetch(`https://viacep.com.br/ws/${cep}/json`)
            .then(res => res.json())
            .then(
                (result) => {
                    setValues({
                        ...values, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep,
                        ['cliente']: { ...values.cliente, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep }
                    });
                },
                (error) => {
                    console.log(error)
                }
            )
        setValues({ ...values, ['cep']: cep, ['endereco']: '' });
    };


    const save = () => {
        const erros = isErrors()
        if (erros) {
            enqueueSnackbar('Preencha todos os campos obrigatorios', {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            });
        } else {

            fetch(`${url}/venda`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                },
                method: 'post',
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            enqueueSnackbar(`Erro de validação ${result.message}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        } else {
                            if (visita) {
                                fetch(`${url}/visitas/finalizar/${visita}`, {
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                                    },
                                    method: 'post'
                                }).then(res => res.json()).then(
                                    (result) => {

                                    },
                                    (error) => {
                                        console.log(error)
                                    }
                                )
                            }
                            setIdSalvo(result.id)
                            setRedirecionar(true)
                            setOpen(false)
                            enqueueSnackbar('Visita salvo com sucesso', {
                                variant: 'success', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        }
                    },
                    (error) => {
                        enqueueSnackbar('Preencha todos os campos obrigatorios', {
                            variant: 'error', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        });
                    }
                )
        }

    }

    function handleValues(values) {
        setValues(values)
    }

    function isErrors() {
        let erros = false;
        error['codigo'] = isBlank(values.codigo)
        erros = erros || isBlank(values.codigo)
        error['total'] = isBlank(values.total)
        erros = erros || isBlank(values.total)
        error['cliente.nome'] = isBlank(values.cliente.nome)
        erros = erros || isBlank(values.cliente.nome)
        setError(error);
        return erros;
    }

    const removerInfo = (index) => () => {
        values.informacoes.splice(index, 1)
        setValues({ ...values, informacoes: values.informacoes })
    }

    const removerMedidas = (index) => () => {
        values.medidas.splice(index, 1)
        setValues({ ...values, medidas: values.medidas })
    }

    const removerProduto = (index) => () => {
        values.produtos.splice(index, 1)
        setValues({ ...values, produtos: values.produtos })
    }

    const removerService = (index) => () => {
        values.servicos.splice(index, 1)
        const totalS = values.servicos.reduce((acumulador, numero, indice, original) => {
            return acumulador += Number(numero.total);
        }, 0)
        setValues({ ...values, servicos: values.servicos, total: (totalS + values.frete - values.desconto).toFixed(2) })
    }

    const addInfo = () => {
        values.informacoes.push({
            id: 0,
            nome: infoAdd
        })
        setValues({ ...values, informacoes: values.informacoes })
        setinfoAdd('')
    }

    const addMetragem = () => {
        values.medidas.push({
            ...medida,
            id: 0
        })
        setValues({ ...values, medidas: values.medidas })
        setMedida({
            ambiente: '',
            metragem: ''
        })
    }

    useEffect(() => {
        if (!id)
            fetch(`${url}/venda/next`,
                {
                    headers: {
                        'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                    }
                })
                .then(res => res.json())
                .then(
                    (code) => {
                        fetch(`${url}/venda/informacoes`, {
                            headers: {
                                'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                            }
                        })
                            .then(res => res.json())
                            .then(
                                (infos) => {
                                    if (visita)
                                        fetch(`${url}/visitas/${visita}`, {
                                            headers: {
                                                'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                                            }
                                        })
                                            .then(res => res.json())
                                            .then(
                                                (result) => {
                                                    handleValues({ ...values, informacoes: infos, codigo: code.code, cliente: result.cliente, cep: result.cep, endereco: result.endereco, numero: result.numero, complemento: result.complemento })
                                                },
                                                (error) => {
                                                    console.log(error)
                                                }
                                            )
                                    else
                                        handleValues({ ...values, informacoes: infos, codigo: code.code })
                                },
                                (error) => {
                                    console.log(error)
                                }
                            )
                    },
                    (error) => {
                        console.log(error)
                    }
                )


    }, []);

    useEffect(() => {
        fetch(`${url}/produtos`,
            {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setOptions(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, []);

    useEffect(() => {
        fetch(`${url}/servicos`,
            {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setOptionsService(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, []);

    useEffect(() => {
        if (id)
            fetch(`${url}/venda/${id}`, {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);




    const classes = useStyles();
    return (
        <Layout>
            {redirecionar ? <Redirect to={`/venda/pdf/${idSalvo}`}></Redirect> : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>{id ? 'Editar Pedido' : 'Adicionar Pedido'}</Title>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField required id="codigo" label="Código" fullWidth onChange={handleChange('codigo')} value={values.codigo} error={error['codigo']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        value={values.data}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel>Frete</InputLabel>
                                    <Input
                                        id="frete"
                                        type="number"
                                        value={values.frete}
                                        onChange={handleChange('frete')}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel>Desconto Especial</InputLabel>
                                    <Input
                                        type="number"
                                        value={values.desconto}
                                        onChange={handleChange('desconto')}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel required>Forma de Pagamento</InputLabel>
                                    <Select
                                        labelId="unidade"
                                        id="unidade-select"
                                        value={values.pagamento}
                                        onChange={handleChange('pagamento')}
                                    >
                                        <MenuItem value={'DINHEIRO'}>Dinheiro</MenuItem>
                                        <MenuItem value={'CARTAO'}>Cartão</MenuItem>
                                        <MenuItem value={'CHEQUE'}>Cheque</MenuItem>
                                        <MenuItem value={'BOLETO'}>Boleto</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required id="obs" label="Observação" fullWidth onChange={handleChange('obs')} value={values.obs} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>Informações Adicionais</Title>
                            </Grid>
                            <Grid item xs={8} md={10}>
                                <TextField required id="info" label="Informação Adicional" fullWidth onChange={handleInfoAdd} value={infoAdd} />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Button variant="contained" style={{
                                    float: "right"
                                }} onClick={addInfo} onMouseDown={handleMouseDownPassword} >
                                    Adicionar
                                    </Button>
                            </Grid>
                            <Grid container style={{ padding: 10, maxHeight: 400, overflow: 'scroll' }}>
                                {values.informacoes.map((info, index) =>
                                    <Grid item xs={12} key={index}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}><Typography component="h2" variant="h6" color="initial" gutterBottom>
                                                {info.nome}
                                            </Typography></Grid>
                                            <Grid item xs={4}>
                                                <Tooltip title="Remover informação">
                                                    <IconButton style={{
                                                        float: 'right'
                                                    }} onMouseDown={handleMouseDownPassword} onClick={removerInfo(index)}>
                                                        <DeleteIcon></DeleteIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>Obra</Title>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField required label="CEP" fullWidth onChange={handleCepChange} value={values.cep} error={error['cep']} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField required label="Endereço" fullWidth onChange={handleChange('endereco')} value={values.endereco} error={error['endereco']} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField required label="Número" fullWidth onChange={handleChange('numero')} value={values.numero} error={error['numero']} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField label="Complemento" fullWidth onChange={handleChange('complemento')} value={values.complemento} error={error['complemento']} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><Title>Cliente</Title></Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required label="Nome" fullWidth onChange={handleChangeCliente('nome')} value={values.cliente.nome} error={error['cliente.nome']} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField label="Telefone" fullWidth onChange={handleChangeCliente('telefone')} value={values.cliente.telefone} error={error['cliente.telefone']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="CPF / CNPJ" fullWidth onChange={handleChangeCliente('cpfCnpj')} value={values.cliente.cpfCnpj} error={error['cliente.cpfCnpj']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="CEP" fullWidth onChange={handleCepClienteChange} value={values.cliente.cep} error={error['cliente.cep']} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField label="Endereço" fullWidth onChange={handleChangeCliente('endereco')} value={values.cliente.endereco} error={error['cliente.endereco']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Número" fullWidth onChange={handleChangeCliente('numero')} value={values.cliente.numero} error={error['cliente.numero']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Complemento" fullWidth onChange={handleChangeCliente('complemento')} value={values.cliente.complemento} error={error['cliente.complemento']} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><Title>Serviços</Title></Grid>
                            <Grid item xs={8} md={10}>
                                <Autocomplete
                                    options={optionsService}
                                    autoHighlight
                                    getOptionLabel={(option) => option.descricao}
                                    onChange={(event, newValue) => {
                                        handleChangeAutoService(newValue)
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.descricao}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Serviços"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Button variant="contained" style={{
                                    float: "right"
                                }} onClick={handleAddServico} onMouseDown={handleMouseDownPassword} >
                                    Adicionar
                                    </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {values.servicos.map((servico, index) =>
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <TextField label="Descrição" fullWidth disabled value={servico.descricao} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Código" fullWidth disabled value={servico.codigo} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Unidade" fullWidth disabled value={servico.unidade === 'UNIDADE' ? 'Unidade' : 'Comprimento'} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Valor por Item" fullWidth disabled value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(servico.preco)} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.margin}>
                                                        <InputLabel required>Total</InputLabel>
                                                        <Input
                                                            required
                                                            type="number"
                                                            value={servico.total}
                                                            onChange={handleChangeService(index, 'total')}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.margin}>
                                                        <InputLabel required>M²</InputLabel>
                                                        <Input
                                                            required
                                                            type="number"
                                                            value={servico.metragem}
                                                            onChange={handleChangeService(index, 'metragem')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="Remover">
                                                <IconButton onMouseDown={handleMouseDownPassword} onClick={removerService(index)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><Title>Produtos</Title></Grid>
                            <Grid item xs={8} md={10}>
                                <Autocomplete
                                    options={options}
                                    autoHighlight
                                    getOptionLabel={(option) => option.descricao}
                                    onChange={(event, newValue) => {
                                        handleChangeAuto(newValue)
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.descricao}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Produtos"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Button variant="contained" style={{
                                    float: "right"
                                }} onClick={handleAddProduto} onMouseDown={handleMouseDownPassword} >
                                    Adicionar
                                    </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {values.produtos.map((produto, index) =>
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4}>
                                                    <TextField label="Descrição" fullWidth disabled value={produto.descricao} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Código" fullWidth disabled value={produto.codigo} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Unidade" fullWidth disabled value={produto.unidade === 'UNIDADE' ? 'Unidade' : 'Comprimento'} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Largura" fullWidth disabled value={produto.largura.toFixed(2)} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Comprimento" fullWidth disabled value={produto.comprimento.toFixed(2)} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.margin}>
                                                        <InputLabel required>Quantidade</InputLabel>
                                                        <Input
                                                            required
                                                            type="number"
                                                            value={produto.qnt}
                                                            onChange={handleChangeProd(index, 'qnt')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                {produto.unidade !== 'UNIDADE' ?
                                                    <Grid item xs={12} md={2}>
                                                        <TextField label="M²" fullWidth disabled value={(produto.comprimento * produto.largura * produto.qnt).toFixed(2)} />
                                                    </Grid> : null}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="Remover">
                                                <IconButton onMouseDown={handleMouseDownPassword} onClick={removerProduto(index)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>Medidas</Title>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField required label="Ambiente" fullWidth onChange={handleChangeMetragem('ambiente')} value={medida.ambiente} />
                            </Grid>
                            <Grid item xs={2} md={3}>
                                <TextField required label="Largura" fullWidth onChange={handleChangeMetragem('largura')} value={medida.largura} />
                            </Grid>
                            <Grid item xs={2} md={3}>
                                <TextField required label="Comprimento" fullWidth onChange={handleChangeMetragem('comprimento')} value={medida.comprimento} />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Button variant="contained" style={{
                                    float: "right"
                                }} onClick={addMetragem} onMouseDown={handleMouseDownPassword} >
                                    Adicionar
                                    </Button>
                            </Grid>
                            {values.medidas.map((info, index) =>
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}><Typography component="h2" variant="h6" color="initial" gutterBottom>
                                            {info.ambiente}
                                        </Typography></Grid>
                                        <Grid item xs={2}><Typography component="h2" variant="h6" color="initial" gutterBottom>
                                            {info.largura}
                                        </Typography></Grid>
                                        <Grid item xs={2}><Typography component="h2" variant="h6" color="initial" gutterBottom>
                                            {info.comprimento}
                                        </Typography></Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Remover informação">
                                                <IconButton style={{
                                                    float: 'right'
                                                }} onMouseDown={handleMouseDownPassword} onClick={removerMedidas(index)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl className={classes.margin}>
                                    <InputLabel required>Total</InputLabel>
                                    <Input
                                        required
                                        disabled
                                        type="number"
                                        onChange={handleChange('total')}
                                        value={values.total}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Button variant="contained" color="primary" onMouseDown={handleMouseDownPassword} onClick={handleClickOpen}>
                                    Aceitar orçamento
                                    </Button>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Button href="/venda" color="secondary" variant="contained" style={{
                                    float: "right"
                                }}>
                                    Cancelar
                                    </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Orçamento de Serviço
        </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Valor total do orçamento {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(values.total)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="secondary" variant="contained" onClick={handleClose} onMouseDown={handleMouseDownPassword}>
                        Cancelar
          </Button>
                    <Button onClick={save} color="primary" variant="contained" onMouseDown={handleMouseDownPassword}>
                        Aceitar
          </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    )
}