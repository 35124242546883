import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Dashboard from './Dashboard'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import SignIn from './SignIn';
import Usuarios from './Usuarios';
import UsuarioAdd from './UsuariosAdd';
import { SnackbarProvider } from 'notistack';
import Produtos from './Produtos';
import ProdutoAdd from './ProdutosAdd';
import Servicos from './Servicos';
import ServicoAdd from './ServicosAdd'
import VisitaAdd from './VisitaAdd'
import Visitas from './Visitas'
import { PrivateRoute, Role } from './_helpers';
import Balcao from './Balcao';
import BalcaoAdd from './BalcaoAdd';
import Venda from './Vendas';
import VendaAdd from './VendaAdd';
import PdfVisita from './PdfVisita'
import PdfBalcao from './PdfBalcao';
import PdfVenda from './PdfVenda';
import PdfRelatorio from './PdfRelatorio';

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <BrowserRouter basename="/">
      <Switch>
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/dashboard" component={Dashboard} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/relatorio/pdf/:inicio/:fim" component={PdfRelatorio} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/produtos/add" component={ProdutoAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/produtos/edit/:id" component={ProdutoAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/produtos" component={Produtos} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/servicos/add" component={ServicoAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/servicos/edit/:id" component={ServicoAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/servicos" component={Servicos} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/visita/add" component={VisitaAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/visita/edit/:id" component={VisitaAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/visita/pdf/:id" component={PdfVisita} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/visitas" component={Visitas} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/balcao/add" component={BalcaoAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/balcao/pdf/:id" component={PdfBalcao} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/balcao/edit/:id" component={BalcaoAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/balcao" component={Balcao} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/venda/edit/:id" component={VendaAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/venda/add/:visita" component={VendaAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/venda/add" component={VendaAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/venda/pdf/:id" component={PdfVenda} />
        <PrivateRoute roles={[Role.ADMINISTRADOR, Role.USUARIO]} path="/venda" component={Venda} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/usuarios/add" component={UsuarioAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/usuarios/edit/:id" component={UsuarioAdd} />
        <PrivateRoute roles={[Role.ADMINISTRADOR]} path="/usuarios" component={Usuarios} />
        <Route path="/login" component={SignIn} />
        <Route path="/" component={SignIn} />
      </Switch>
    </ BrowserRouter>
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
