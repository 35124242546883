import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

export const url = "https://gbforros.co"
// export const url = "http://localhost:8080"

export const TOKEN_KEY = "token_user";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => JSON.parse(localStorage.getItem(TOKEN_KEY));
export const isAdmin = () => JSON.parse(localStorage.getItem(TOKEN_KEY)).usuario.perfil === Role.ADMINISTRADOR;

export const loginStorage = token => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Route {...rest} render={props => {
      const currentUser = getToken()
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }

      // check if route is restricted by roleq
      if (roles && roles.indexOf(currentUser.usuario.perfil) === -1) {
        // role not authorised so redirect to home page
        enqueueSnackbar("Usuário sem permissão de acesso", {
          variant: 'error', anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        })
        return <Redirect to={{ pathname: '/produtos' }} />
      }

      // authorised so return component
      return <Component {...props} />
    }} />
  )
}

export const Role = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  USUARIO: 'USUARIO'
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function getSearchAll(array, ids, comparator) {
  return array.filter((obj) => {
    return ids.reduce((acc, id) => {
      if (String(id).includes('data')) {
        return String(formatDate(obj[id])).toLowerCase().includes(String(comparator).toLowerCase()) || acc
      } else
        if (obj['cliente']) {
          return String(obj[id]).toLowerCase().includes(String(comparator).toLowerCase()) || String(obj['cliente'][id]).toLowerCase().includes(String(comparator).toLowerCase()) || acc
        } else {
          return String(obj[id]).toLowerCase().includes(String(comparator).toLowerCase()) || acc
        }
    }, false)
  });
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function formatDate(date) {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  return `${da}/${mo}/${ye}`
}

export function formatDateURL(date) {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  return `${ye}-${mo.length < 2 ? `0${mo}`: mo}-${da}`
}


export function SortTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, check } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {check ?
          <TableCell>
          </TableCell> : null}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SortTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  check: PropTypes.bool,
};