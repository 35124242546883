import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, Button, Divider, Tooltip, IconButton } from '@material-ui/core';
import Title from './Title';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isBlank } from './validacao'
import { url, getToken } from './_helpers'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import ptLocale from "date-fns/locale/pt-BR";

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function BalcaoAdd() {
    let { id } = useParams();
    const [error, setError] = useState({});
    const [redirecionar, setRedirecionar] = useState(false);
    const [selecionado, setSelecionado] = useState();
    const [idSalvo, setIdSalvo] = useState(0);
    const [options, setOptions] = useState([]);
    const [totalP, setTotalP] = useState(0)
    const [values, setValues] = useState({
        id: 0,
        codigo: '',
        frete: 0,
        desconto: 0,
        pagamento: 'DINHEIRO',
        total: 0,
        obs: '',
        data: new Date(),
        cliente: {
            nome: '',
            cpfCnpj: '',
            celular: '',
            telefone: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: ''
        },
        produtos: []

    });
    const { enqueueSnackbar } = useSnackbar();


    const handleChange = (prop) => (event) => {
        let total = 0
        if (prop === 'frete')
            total = (event.target.value - values.desconto + totalP).toFixed(2)
        if (prop === 'desconto')
            total = (values.frete - event.target.value + totalP).toFixed(2)
        setValues({ ...values, [prop]: event.target.value, total });
    };

    const handleChangeProd = (index, prop) => (event) => {
        values.produtos[index][prop] = event.target.value
        values.produtos[index].total = ((values.produtos[index].preco - values.produtos[index].desconto) * values.produtos[index].qnt).toFixed(2)
        const total = values.produtos.reduce((acumulador, numero, indice, original) => {
            return acumulador += Number(numero.total);
        }, 0)
        setValues({ ...values, produtos: values.produtos, total: (values.frete - values.desconto + total).toFixed(2) });
        setTotalP(total)
    };


    const handleChangeAuto = (item) => {
        setSelecionado(item)
        return true
    };

    const handleAddProduto = () => {
        if (selecionado) {
            values.produtos.unshift({ ...selecionado, total: selecionado.preco, desconto: 0, qnt: 1, id: 0 })
            setValues({ ...values, produtos: values.produtos })
            setSelecionado(null)
            const total = values.produtos.reduce((acumulador, numero, indice, original) => {
                return acumulador += Number(numero.total);
            }, 0)
            setValues({ ...values, produtos: values.produtos, total: (values.frete - values.desconto + total).toFixed(2) });
            setTotalP(total)
        }
    }

    const handleChangeCliente = (prop) => (event) => {
        setValues({ ...values, cliente: { ...values.cliente, [prop]: event.target.value } });
    };


    const handleCepClienteChange = (event) => {
        const cep = event.target.value
        fetch(`https://viacep.com.br/ws/${cep}/json`)
            .then(res => res.json())
            .then(
                (result) => {
                    setValues({ ...values, cliente: { ...values.cliente, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep } });
                },
                (error) => {
                    console.log(error)
                }
            )
        setValues({ ...values, cliente: { ...values.cliente, ['cep']: cep, ['endereco']: '' } });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDateChange = (date) => {
        setValues({ ...values, data: date });
    };

    const removerProduto = (index) => () => {
        values.produtos.splice(index, 1)
        setValues({ ...values, produtos: values.produtos })
    }


    const save = (salvar) => () => {
        const erros = isErrors()
        if (erros) {
            enqueueSnackbar('Preencha todos os campos obrigatorios', {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            });
        } else {
            fetch(`${url}/${salvar ? 'balcao/orcamento': 'balcao'}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                },
                method: 'post',
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            enqueueSnackbar(`Erro de validação ${result.message}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        } else {
                            setIdSalvo(result.id)
                            setRedirecionar(true)
                            enqueueSnackbar('Visita salvo com sucesso', {
                                variant: 'success', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        }
                    },
                    (error) => {
                        enqueueSnackbar('Preencha todos os campos obrigatorios', {
                            variant: 'error', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        });
                    }
                )
        }
    }

    function isErrors() {
        let erros = false;
        error['codigo'] = isBlank(values.codigo)
        erros = erros || isBlank(values.codigo)
        error['total'] = isBlank(values.total)
        erros = erros || isBlank(values.total)
        error['cliente.nome'] = isBlank(values.cliente.nome)
        erros = erros || isBlank(values.cliente.nome)
        setError(error);
        return erros;
    }

    useEffect(() => {
        if (!id)
            fetch(`${url}/balcao/next`, {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues({ ...values, ['codigo']: result.code });
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);

    useEffect(() => {
        fetch(`${url}/produtos`, {
            headers: {
                'Authorization': `Bearer ${encodeURI(getToken().token)}`
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setOptions(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, []);

    useEffect(() => {
        if (id)
            fetch(`${url}/balcao/${id}`, {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);



    const classes = useStyles();
    return (
        <Layout>
            {redirecionar ? <Redirect to={`/balcao/pdf/${idSalvo}`} ></Redirect> : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>{id ? 'Editar Pedido' : 'Adicionar Pedido'}</Title>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField required id="codigo" disabled label="Código" fullWidth onChange={handleChange('codigo')} value={values.codigo} error={error['codigo']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        value={values.data}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel>Frete</InputLabel>
                                    <Input
                                        id="frete"
                                        type="number"
                                        value={values.frete}
                                        onChange={handleChange('frete')}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth className={classes.margin}>
                                    <InputLabel>Desconto Especial</InputLabel>
                                    <Input
                                        id="frete"
                                        type="number"
                                        value={values.desconto}
                                        onChange={handleChange('desconto')}
                                        startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel required>Forma de Pagamento</InputLabel>
                                    <Select
                                        labelId="unidade"
                                        id="unidade-select"
                                        value={values.pagamento}
                                        onChange={handleChange('pagamento')}
                                    >
                                        <MenuItem value={'DINHEIRO'}>Dinheiro</MenuItem>
                                        <MenuItem value={'CARTAO'}>Cartão</MenuItem>
                                        <MenuItem value={'CHEQUE'}>Cheque</MenuItem>
                                        <MenuItem value={'BOLETO'}>Boleto</MenuItem>
                                        <MenuItem value={'MERCADO_LIVRE'}>Mercado Pago</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required label="Nome" fullWidth onChange={handleChangeCliente('nome')} value={values.cliente.nome} error={error['cliente.nome']} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField label="Telefone" fullWidth onChange={handleChangeCliente('telefone')} value={values.cliente.telefone} error={error['cliente.telefone']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="CPF / CNPJ" fullWidth onChange={handleChangeCliente('cpfCnpj')} value={values.cliente.cpfCnpj} error={error['cliente.cpfCnpj']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="CEP" fullWidth onChange={handleCepClienteChange} value={values.cliente.cep} error={error['cliente.cep']} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField label="Endereço" fullWidth onChange={handleChangeCliente('endereco')} value={values.cliente.endereco} error={error['cliente.endereco']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Número" fullWidth onChange={handleChangeCliente('numero')} value={values.cliente.numero} error={error['cliente.numero']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Complemento" fullWidth onChange={handleChangeCliente('complemento')} value={values.cliente.complemento} error={error['cliente.complemento']} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="obs" label="Observação" fullWidth onChange={handleChange('obs')} value={values.obs} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><Title>Produtos</Title></Grid>
                            <Grid item xs={8} md={10}>
                                <Autocomplete
                                    options={options}
                                    autoHighlight
                                    getOptionLabel={(option) => option.descricao}
                                    onChange={(event, newValue) => {
                                        handleChangeAuto(newValue)
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.descricao}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Produtos"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Button variant="contained" style={{
                                    float: "right"
                                }} onClick={handleAddProduto} onMouseDown={handleMouseDownPassword} >
                                    Adicionar
                                    </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {values.produtos.map((produto, index) =>
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10} key={produto.id}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={4}>
                                                    <TextField label="Descrição" fullWidth disabled value={produto.descricao} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Código" fullWidth disabled value={produto.codigo} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Unidade" fullWidth disabled value={produto.unidade === 'UNIDADE' ? 'Unidade' : 'Comprimento'} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Largura" fullWidth disabled value={produto.largura.toFixed(2)} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <TextField label="Comprimento" fullWidth disabled value={produto.comprimento.toFixed(2)} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.margin}>
                                                        <InputLabel required>Valor por item</InputLabel>
                                                        <Input
                                                            type="number"
                                                            onChange={handleChangeProd(index, 'preco')}
                                                            value={produto.preco}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.margin}>
                                                        <InputLabel required>Quantidade</InputLabel>
                                                        <Input
                                                            required
                                                            type="number"
                                                            value={produto.qnt}
                                                            onChange={handleChangeProd(index, 'qnt')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth className={classes.margin}>
                                                        <InputLabel required>Desconto</InputLabel>
                                                        <Input
                                                            type="number"
                                                            onChange={handleChangeProd(index, 'desconto')}
                                                            value={produto.desconto}
                                                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                {produto.unidade !== 'UNIDADE' ?
                                                    <Grid item xs={12} md={2}>
                                                        <TextField label="M²" fullWidth disabled value={(produto.comprimento * produto.largura * produto.qnt).toFixed(2)} />
                                                    </Grid> : null}
                                                <Grid item xs={12} md={2}>
                                                    <TextField disabled label="Total" fullWidth value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produto.total)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="Remover">
                                                <IconButton onMouseDown={handleMouseDownPassword} onClick={removerProduto(index)}>
                                                    <DeleteIcon></DeleteIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField disabled label="Total" fullWidth value={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(values.total)} />
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Button variant="contained" color="primary" onMouseDown={handleMouseDownPassword} onClick={save(false)}>
                                    Aceitar orçamento
                                    </Button>
                            </Grid>
                            <Grid item xs={4} md={3}>
                                <Button variant="contained" onMouseDown={handleMouseDownPassword} onClick={save(true)}>
                                    Pré orçamento
                                    </Button>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <Button href="/balcao" color="secondary" variant="contained" style={{
                                    float: "right"
                                }}>
                                    Cancelar
                                    </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}