import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Title from './Title';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isBlank } from './validacao'
import { Role, url, getToken } from './_helpers'
import Draggable from 'react-draggable';


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


export default function UsuarioAdd(props) {
    let { id } = useParams();
    const [error, setError] = useState([]);
    const [redirecionar, setRedirecionar] = useState(false);
    const [values, setValues] = useState({
        id: 0,
        senha: '',
        login: '',
        nome: '',
        loja: 'SAUDE',
        perfil: Role.USUARIO,
        showPassword: false,
    });
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const remove = () => {
        const token = encodeURI(getToken().token)
        fetch(`${url}/usuario/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'delete',
        }).then(res => res.json()).then((result) => { 
                if (result.error) {
                    enqueueSnackbar(`Erro ao remover usuário`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        }
                    });
                } else {
                    enqueueSnackbar('Usuário removido com sucesso', {
                        variant: 'success', anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        }
                    });
                    setRedirecionar(true);
                }
            },
                (error) => { console.log(error)})
    }

    const save = () => {
        const erros = isErrors()
        if (erros) {
            enqueueSnackbar('Preencha todos os campos obrigatorios', {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            });
        } else {
            const token = encodeURI(getToken().token)
            fetch(`${url}/usuario`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'post',
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            enqueueSnackbar(`Erro de validação ${result.message}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        } else {
                            enqueueSnackbar('Usuário salvo com sucesso', {
                                variant: 'success', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                            setRedirecionar(true);
                        }
                    },
                    (error) => {
                        enqueueSnackbar('Preencha todos os campos obrigatorios', {
                            variant: 'error', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        });
                    }
                )
        }

    }

    const isErrors = () => {
        error['nome'] = isBlank(values.nome)
        error['login'] = isBlank(values.login)
        error['password'] = isBlank(values.senha)
        setError(error)
    }

    useEffect(() => {
        if (id)
            fetch(`${url}/usuario/${id}`, {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues({ ...result, senha: '' })
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);


    const classes = useStyles();
    return (
        <Layout>
            {redirecionar ? <Redirect to="/usuarios"></Redirect> : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>{id ? 'Editar Usuário' : 'Adicionar Usuário'}</Title>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required id="nome" label="Nome" fullWidth onChange={handleChange('nome')} value={values.nome} error={error['nome']} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required id="login" label="Login" fullWidth onChange={handleChange('login')} value={values.login} error={error['login']} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth autoComplete="off">
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input
                                        id="password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.senha}
                                        onChange={handleChange('senha')}
                                        error={error['password']}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    autoComplete="off"
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="perfil">Perfil</InputLabel>
                                    <Select
                                        labelId="perfil"
                                        id="perfil-select"
                                        value={values.perfil}
                                        onChange={handleChange('perfil')}
                                    >
                                        <MenuItem value={Role.USUARIO}>Usuário</MenuItem>
                                        <MenuItem value={Role.ADMINISTRADOR}>Administrador</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="loja">Loja</InputLabel>
                                    <Select
                                        labelId="loja"
                                        id="loja-select"
                                        value={values.loja}
                                        onChange={handleChange('loja')}
                                    >
                                        <MenuItem value={'SAUDE'}>Saúde</MenuItem>
                                        <MenuItem value={'VILA_MARIA'}>Vila Maria</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" onMouseDown={handleMouseDownPassword} onClick={save}>
                                    Salvar
                                    </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button href="/usuarios" variant="contained" style={{
                                    float: "right"
                                }}>
                                    Voltar
                                    </Button>
                                {id && <Button variant="contained" color="secondary" style={{
                                    float: "right",
                                    marginLeft: 4,
                                    marginRight: 4
                                }} onMouseDown={handleMouseDownPassword} onClick={handleClickOpen}>
                                    Remover
                                    </Button>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Remover Usuário
        </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja remover este usuário ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="secondary" variant="contained" onClick={handleClose} onMouseDown={handleMouseDownPassword}>
                        Cancelar
          </Button>
                    <Button onClick={remove} color="primary" variant="contained" onMouseDown={handleMouseDownPassword}>
                        Aceitar
          </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    )
}