import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import { url, getToken, formatDate } from './_helpers'

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function BalcaoPendente(props) {
  const classes = useStyles();
  const [total, setTotal] = useState(0.0);
  const curr = new Date(); // get current date
  const lastday = new Date(curr).toUTCString();
  useEffect(() => {
    fetch(`${url}/${props.url}/pendentes/semana/${props.loja}`, {
      headers: {
        'Authorization': `Bearer ${encodeURI(getToken().token)}`,
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          setTotal(result.total)
        },
        (error) => {
          console.log(error)
        }
      )
  }, []);
  return (
    <React.Fragment>
      <Title>{props.url === 'balcao'? 'Vendas realizadas no balcão ': 'Vendas de serviço '}pendentes {props.loja === 'SAUDE' ? 'Saúde': 'Vila Maria'}</Title>
      <Typography component="p" variant="h4">
        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
       Até {formatDate(lastday)}
      </Typography>
      <div>
        <Link color="primary" href={`/${props.url}`}>
          Ir para {props.url === 'balcao' ? 'balcão' : 'vendas'}
        </Link>
      </div>
    </React.Fragment>
  );
}