import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { url, getToken } from './_helpers'
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image, Canvas } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import { PDFViewer } from '@react-pdf/renderer';
import { formatDate } from './_helpers'

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px',
        height: '800px'
    },
    fixedHeight: {
        height: 240,
    },
}));

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: '20pt',
        width: '100%'
    },
    text: {
        width: "100%",
        backgroundColor: "#f0f0f0",
        color: "#212121"
    }
});

const loja = {
    SAUDE: {
        endereco: 'Rua Francisco Tapajós 149 – Saúde – São Paulo – SP',
        telefone: '(11) 5068-2783',
        email: 'saude@gbforros.com.br'
    },
    VILA_MARIA: {
        endereco: 'Rua Dias da Silva, nº 1131 – Vila Maria – São Paulo – SP',
        telefone: '(11) 2954-3736',
        email: 'vilamaria@gbforros.com.br'
    }
}

const defaultStyle = ReactPDF.Style = {
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',
    paddingTop: '5px',
    paddingBottom: '5px',
    wordWrap: "break-word",
    whiteSpace: "pre-wrap"
};

export default function PdfBalcao(props) {
    let { id } = useParams();
    const [values, setValues] = useState({
        codigo: '',
        frete: 0,
        situacaoVenda: '',
        desconto: 0,
        formaPagamento: 'DINHEIRO',
        total: 0,
        loja: 'VILA_MARIA',
        obs: '',
        data: new Date(),
        cliente: {
            nome: '',
            cpfCnpj: '',
            celular: '',
            telefone: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: ''
        },
        produtos: []

    });


    useEffect(() => {
        if (id)
            fetch(`${url}/balcao/${id}`, {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        setValues(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);


    const classes = useStyles();
    return (
        <Layout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <PDFViewer style={{
                            height: '800px'
                        }}>
                            <Document title={`op-${values.codigo}`}>
                                <Page size="A4" style={styles.page}>
                                    <View style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', border: "1pt solid black" }}>
                                        <View style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            display: "flex"

                                        }}>
                                            <View style={{ ...defaultStyle, width: '30%', border: "1pt solid black", paddingTop: '30pt', textAlign: 'center' }}>
                                                <Image source={require('./gb-logo-site.png')} style={{ width: '40%', marginLeft: 40 }} />
                                                <Text>Forros de PVC</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '70%', border: "1pt solid black", textAlign: 'center', }}>
                                                <View style={{ ...defaultStyle, width: '100%', borderBottom: "1pt solid black", fontSize: '16pt' }}>
                                                    <Text>Clanil Comércio de Plasticos LTDA-ME</Text>
                                                </View>
                                                <View style={{ ...defaultStyle, width: '100%', fontSize: '10pt' }}>
                                                    <Text style={{ marginBottom: '5pt' }}>CNPJ: 09.013.810/0001-57 - IE: 149.780.989.110</Text>
                                                    <Text style={{ marginBottom: '5pt' }}>{loja[values.loja].endereco}</Text>
                                                    <Text style={{ marginBottom: '5pt' }}>{`Telefone: ${loja[values.loja].telefone}`}</Text>
                                                    <Text style={{ marginBottom: '5pt' }}>{`Website: https://gbforros.com Email: ${loja[values.loja].email}`}</Text>
                                                </View>
                                                <View style={{ ...defaultStyle, width: '100%', fontSize: '14pt', borderTop: "1pt solid black" }}>
                                                    <Text>{`${values.situacaoVenda === 'ACEITO' ? '' : 'PRE '}OP N.º ${values.codigo} - Data: ${formatDate(values.data)}`}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>

                                            <View style={{ ...defaultStyle, fontSize: '10pt', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt', fontSize: '12pt' }}>Dados do Cliente</Text>
                                                <Text style={{ marginBottom: '5pt' }}>Nome: {values.cliente.nome}</Text>
                                                <Text style={{ marginBottom: '5pt' }}>CEP: {values.cliente.cep}</Text>
                                                <Text style={{ marginBottom: '5pt' }}>Endereço: {values.cliente.endereco}, Número: {values.cliente.numero}</Text>
                                                {!!values.cliente.complemento && <Text style={{ marginBottom: '5pt' }}>Complemento: {values.cliente.complemento}</Text>}
                                                <Text style={{ marginBottom: '5pt' }}>Celular: {values.cliente.celular} | Telefone: {values.cliente.telefone}</Text>
                                                {!!values.cliente.email && <Text style={{ marginBottom: '5pt' }}>Email: {values.cliente.email}</Text>}
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, fontSize: '10pt', paddingLeft: '15px' }}>
                                    <Text style={{ marginBottom: '5pt', fontSize: '12pt' }}>Forma de Pagamento: {
                                        values.pagamento === 'BOLETO' ? 'Boleto' : 
                                        (values.pagamento === 'CHEQUE' ? 'Cheque' : 
                                        values.pagamento === 'DINHEIRO' ? 'Dinheiro' : (
                                            values.pagamento === 'CARTAO' ? 'Cartão' : 'MERCADO_LIVRE'
                                             ))
                                    }</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '55%', fontSize: '12pt', fontWeight: '700', paddingLeft: '5px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>Descrição</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '15%', fontSize: '12pt', fontWeight: '700', paddingLeft: '5px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>Quantidade</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '15%', fontSize: '12pt', fontWeight: '700', paddingLeft: '5px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>Valor Unitário</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '15%', fontSize: '12pt', fontWeight: '700', paddingLeft: '5px', textAlign: 'left' }}>
                                                <Text style={{ marginBottom: '2pt' }}>Valor Total</Text>
                                            </View>
                                        </View>
                                        {values.produtos.map((produto) => {
                                            return (
                                                <View style={{
                                                    flexDirection: "row",
                                                    width: "100%",
                                                    display: "flex",
                                                    border: "1pt solid black"
                                                }}>
                                                    <View style={{ ...defaultStyle, width: '55%', fontSize: '10pt', paddingLeft: '5px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                        <Text style={{ marginBottom: '2pt' }}>{produto.descricao}</Text>
                                                    </View>
                                                    <View style={{ ...defaultStyle, width: '15%', fontSize: '10pt', paddingLeft: '5px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                        <Text style={{ marginBottom: '2pt' }}>{produto.qnt}</Text>
                                                    </View>
                                                    <View style={{ ...defaultStyle, width: '15%', fontSize: '10pt', paddingLeft: '5px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                        <Text style={{ marginBottom: '2pt' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((produto.preco - produto.desconto))}</Text>
                                                    </View>
                                                    <View style={{ ...defaultStyle, width: '15%', fontSize: '10pt', paddingLeft: '5px', textAlign: 'left' }}>
                                                        <Text style={{ marginBottom: '2pt' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((produto.total))}</Text>
                                                    </View>
                                                </View>
                                            )
                                        })}
                                        <View style={{
                                            position: 'absolute',
                                            bottom: 0
                                        }}>
                                            <View style={{
                                                flexDirection: "row",
                                                justifyContent: "stretch",
                                                width: "100%",
                                                height: "auto",
                                                display: "flex",
                                                border: "1pt solid black"
                                            }}>
                                                <View style={{ ...defaultStyle, width: '100%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                    <Text>Observação: {values.obs}</Text>
                                                </View>
                                            </View>
                                            <View style={{
                                                flexDirection: "row",
                                                width: "100%",
                                                display: "flex",
                                            }}>
                                                <View style={{ ...defaultStyle, width: '50%', border: "1pt solid black", paddingTop: '30pt', fontSize: '12pt', textAlign: 'center', color: 'blue' }}>
                                                    <Text>ACEITAMOS TODOS OS CARTÕES DE CRÉDITO</Text>
                                                </View>
                                                <View style={{
                                                    display: 'flex',
                                                    flexBasis: '100%',
                                                    flexWrap: 'wrap',
                                                    wordWrap: "break-word",
                                                    whiteSpace: "pre-wrap", width: '50%', border: "1pt solid black", textAlign: 'left'
                                                }}>
                                                    <View style={{
                                                        flexDirection: "row",
                                                        display: 'flex', width: '100%', borderBottom: "1pt solid black", fontSize: '10pt', paddingLeft: '4pt'
                                                    }}>
                                                        <View style={{ width: '65%', borderRight: '1pt solid black', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>SUB-TOTAL DO ORÇAMENTO</Text></View>
                                                        <View style={{ width: '35%', paddingLeft: '2pt', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((values.produtos.reduce((acumulador, numero, indice, original) => {
                                                            return acumulador += Number(numero.total);
                                                        }, 0)))}</Text></View>
                                                    </View>
                                                    <View style={{
                                                        flexDirection: "row",
                                                        display: 'flex', width: '100%', borderBottom: "1pt solid black", fontSize: '10pt', paddingLeft: '4pt'
                                                    }}>
                                                        <View style={{ width: '65%', borderRight: '1pt solid black', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>(-) TOTAL DO DESCONTO</Text></View>
                                                        <View style={{ width: '35%', paddingLeft: '2pt', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((values.desconto))}</Text></View>
                                                    </View>
                                                    <View style={{
                                                        flexDirection: "row",
                                                        display: 'flex', width: '100%', borderBottom: "1pt solid black", fontSize: '10pt', paddingLeft: '4pt'
                                                    }}>
                                                        <View style={{ width: '65%', borderRight: '1pt solid black', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>(+) TOTAL DO FRETE</Text></View>
                                                        <View style={{ width: '35%', paddingLeft: '2pt', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((values.frete))}</Text></View>
                                                    </View>
                                                    <View style={{
                                                        flexDirection: "row",
                                                        display: 'flex', width: '100%', fontSize: '9pt', paddingLeft: '4pt'
                                                    }}>
                                                        <View style={{ width: '65%', borderRight: '1pt solid black', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>(=) VALOR TOTAL DO ORÇAMENTO</Text></View>
                                                        <View style={{ width: '35%', paddingLeft: '2pt', paddingTop: '3pt', paddingBottom: '3pt' }}><Text>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((values.total))}</Text></View>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{
                                                flexDirection: "row",
                                                width: "100%",
                                                display: "flex",
                                                border: "1pt solid black",
                                                paddingTop: '30pt',
                                                alignSelf: 'flex-end',
                                            }}>

                                                <View style={{ ...defaultStyle, width: '50%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                    <Text>__________________________________</Text>
                                                    <Text>Aprovação do Cliente</Text>
                                                </View>
                                                <View style={{ ...defaultStyle, width: '50%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                    <Text>__________________________________</Text>
                                                    <Text>Clanil Comércio de Plástico LTDA</Text>
                                                </View>
                                            </View>
                                        </View>


                                    </View>
                                </Page>
                            </Document>
                        </PDFViewer>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}