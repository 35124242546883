import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import { url, loginStorage } from './_helpers'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://gbforros.com/">
        GB Forros
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [redirecionar, setRedirecionar] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSenha = (event) => {
    setSenha(event.target.value)
  }

  const handleLogin = (event) => {
    setLogin(event.target.value)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const sendLogin = () => {
    fetch(`${url}/usuario/auth`, {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify({
        email: login,
        password: senha
      })
  }).then(res => res.json())
      .then(
        (result) => {
          if(result.error){
            enqueueSnackbar(`Usuário e/ou senha incorreto`, {
              variant: 'error', anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
              }
          });
          }else{
            loginStorage(result)
            setRedirecionar(true)
          }
        },
        (error) => {
          enqueueSnackbar(`Usuário e/ou senha incorreto`, {
            variant: 'error', anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }
        });
        }
      )
  }


  return (
    <Container component="main" maxWidth="xs">
      {redirecionar ? <Redirect to="/produtos"></Redirect> : null}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            value={login}
            onChange={handleLogin}
            label="Login"
            autoComplete="off"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            autoComplete="off"
            fullWidth
            value={senha}
            onChange={handleSenha}
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onMouseDown={handleMouseDownPassword}
            onClick={sendLogin}
            className={classes.submit}
          >
            Entrar
          </Button>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}