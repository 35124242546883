import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StyleIcon from '@material-ui/icons/Style';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import Link from '@material-ui/core/Link';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

export const mainListItems = (
  <div>
    <Link href="/dashboard">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link>
    <Link href="/balcao">
      <ListItem button>
        <ListItemIcon>
          <ShoppingBasketIcon />
        </ListItemIcon>
        <ListItemText primary="Venda Balção" />
      </ListItem>
    </Link>
    <Link href="/venda">
      <ListItem button>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Venda Serviços" />
      </ListItem>
    </Link>
    <Link href="/usuarios">
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Usuários" />
      </ListItem>
    </Link>
    <Link href="/produtos">
      <ListItem button>
        <ListItemIcon>
          <StyleIcon />
        </ListItemIcon>
        <ListItemText primary="Produtos" />
      </ListItem>
    </Link>
    <Link href="/servicos">
      <ListItem button>
        <ListItemIcon>
          <FormatPaintIcon />
        </ListItemIcon>
        <ListItemText primary="Serviços" />
      </ListItem>
    </Link>
    <Link href="/visitas">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Visita" />
      </ListItem>
    </Link>
  </div>
);