import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { TableCell, TableRow, Table, TableBody, IconButton, TablePagination, Tooltip, Checkbox, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import Title from './Title';
import { url, stableSort, SortTableHead, getComparator, formatDate, getToken, isAdmin, getSearchAll } from './_helpers'
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useSnackbar } from 'notistack';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const headCells = [
    { id: 'codigo', label: 'Código' },
    { id: 'cliente', label: 'Cliente' },
    { id: 'loja', label: 'Loja' },
    { id: 'data', label: 'Data' },
    { id: 'total', label: 'Valor (R$)' },
    { id: 'opcoes', label: 'Opções' },
];

export default function Balcao() {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [itemsSelect, setItemsSelect] = useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [orderBy, setOrderBy] = React.useState('codigo');
    const [order, setOrder] = React.useState('asc');
    const { enqueueSnackbar } = useSnackbar();

    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value)
    }

    const [id, setId] = React.useState(0);
    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setId(0)
        setOpen(false);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectItem = (id) => (event) => {
        console.log(Object.values(itemsSelect).reduce((t, n) => t || n, false))
        setItemsSelect({ ...itemsSelect, [id]: event.target.checked })
    }

    const handleLiquidar = () => {
        const ids = Object.keys(itemsSelect).filter(id => itemsSelect[id]).map(id => Number(id))
        fetch(`${url}/balcao/liquidar`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${encodeURI(getToken().token)}`,
            },
            method: 'post',
            body: JSON.stringify(ids)
        }).then(res => res.json()).then(
            (result) => {
                if (result.error) {
                    enqueueSnackbar(`Erro ao liquidar ${result.message}`, {
                        variant: 'error', anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        }
                    });
                } else {
                    enqueueSnackbar('Pedidos liquidados com sucesso', {
                        variant: 'success', anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        }
                    });
                    setItemsSelect({})
                    listar()
                }
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const remover = () => {
        const token = encodeURI(getToken().token)
        fetch(`${url}/balcao/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            method: 'delete',
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                enqueueSnackbar(`Erro ao remover item`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }
                });
            } else {
                enqueueSnackbar('Item removido com sucesso', {
                    variant: 'success', anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }
                });
                setOpen(false);
                listar();
            }
        },
            (error) => { console.log(error) })
    }

    function listar() {
        fetch(`${url}/balcao/status/PENDENTE`,
            {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    useEffect(() => {
        listar()
    }, []);

    return (<Layout>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Title>Pedidos Balção</Title>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required label="Buscar" fullWidth onChange={handleSearch} value={search} />
                        </Grid>
                        <Grid item xs={2} style={{
                            textAlign: "right"
                        }}>
                            {Object.values(itemsSelect).reduce((t, n) => t || n, false) ?
                                <Tooltip title="Liquidar">
                                    <IconButton onClick={
                                        handleLiquidar
                                    } color="secondary">
                                        <AssignmentTurnedInIcon></AssignmentTurnedInIcon>
                                    </IconButton>
                                </Tooltip> : null}
                            <IconButton href="/balcao/add">
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Table size="small">
                        <SortTableHead
                            classes={classes}
                            check={isAdmin()}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={items.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(getSearchAll(items, ['codigo', 'loja', 'data', 'preco', 'nome'], search), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow key={item.id} style={{ height: 53 }}>
                                    {isAdmin() ? <TableCell>
                                        {item.situacaoVenda === 'ACEITO' ? <Checkbox
                                            checked={itemsSelect[item.id]}
                                            onChange={handleSelectItem(item.id)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        /> : null }
                                    </TableCell> : null}
                                    <TableCell>{item.codigo}</TableCell>
                                    <TableCell>{item.cliente.nome}</TableCell>
                                    <TableCell>{item.loja === 'SAUDE' ? 'Saúde' : 'Vila Maria'}</TableCell>
                                    <TableCell>{formatDate(item.data)}</TableCell>
                                    <TableCell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}</TableCell>
                                    <TableCell >
                                        <Tooltip title="Imprimir">
                                            <IconButton href={`/balcao/pdf/${item.id}`}>
                                                <PrintIcon></PrintIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                            <IconButton href={`/balcao/edit/${item.id}`}>
                                                <EditIcon></EditIcon>
                                            </IconButton>
                                        </Tooltip>
                                        {isAdmin() &&
                                            <Tooltip title="Deletar">
                                                <IconButton onClick={
                                                    () => {
                                                        setId(item.id);
                                                        setOpen(true);
                                                    }
                                                } onMouseDown={handleMouseDownPassword}>
                                                    <DeleteIcon></DeleteIcon>
                                                </IconButton>
                                            </Tooltip>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={items.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Pedidos por pagina"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? `${count}` : `more than ${to}`}`}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Table>
                </Paper>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Remover Item
        </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Deseja remover este Item ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="secondary" variant="contained" onClick={handleClose} onMouseDown={handleMouseDownPassword}>
                        Cancelar
          </Button>
                    <Button color="primary" variant="contained" onClick={remover} onMouseDown={handleMouseDownPassword}>
                        Aceitar
          </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    </Layout>)
}