import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import ptLocale from "date-fns/locale/pt-BR";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, Button, Link, Snackbar } from '@material-ui/core';
import Title from './Title';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isBlank } from './validacao'
import { url, getToken } from './_helpers'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
}));


export default function VisitaAdd(props) {
    let { id } = useParams();
    const [error, setError] = useState({});
    const [redirecionar, setRedirecionar] = useState(false);
    const [values, setValues] = useState({
        id: '',
        codigo: '',
        data: new Date(),
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        acabamento: '',
        observacao: '',
        cliente: {
            nome: '',
            cpfCnpj: '',
            celular: '',
            telefone: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: ''
        }
    });
    const { enqueueSnackbar } = useSnackbar();


    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeCliente = (prop) => (event) => {
        setValues({ ...values, ['cliente']: { ...values.cliente, [prop]: event.target.value } });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDateChange = (date) => {
        setValues({ ...values, ['data']: date });
    };

    const handleCepChange = (event) => {
        const cep = event.target.value
        fetch(`https://viacep.com.br/ws/${cep}/json`)
            .then(res => res.json())
            .then(
                (result) => {
                    setValues({
                        ...values, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep,
                        ['cliente']: { ...values.cliente, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep }
                    });
                },
                (error) => {
                    console.log(error)
                }
            )
        setValues({ ...values, ['cep']: cep, ['endereco']: '' });
    };

    const handleCepClienteChange = (event) => {
        const cep = event.target.value
        fetch(`https://viacep.com.br/ws/${cep}/json`)
            .then(res => res.json())
            .then(
                (result) => {
                    setValues({ ...values, ['cliente']: { ...values.cliente, ['endereco']: `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`, ['cep']: cep } });
                },
                (error) => {
                    console.log(error)
                }
            )
        setValues({ ...values, ['cliente']: { ...values.cliente, ['cep']: cep, ['endereco']: '' } });
    };


    const save = () => {
        console.log(values)
        const erros = isErrors()
        console.log(erros)
        if (erros) {
            enqueueSnackbar('Preencha todos os campos obrigatorios', {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            });
        } else {
            console.log(values)
            fetch(`${url}/visitas`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                },
                method: 'post',
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            enqueueSnackbar(`Erro de validação ${result.message}`, {
                                variant: 'error', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        } else {
                            setRedirecionar(true)
                            enqueueSnackbar('Visita salvo com sucesso', {
                                variant: 'success', anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }
                            });
                        }
                    },
                    (error) => {
                        enqueueSnackbar('Preencha todos os campos obrigatorios', {
                            variant: 'error', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        });
                    }
                )
        }

    }

    function isErrors() {
        let erros = false;
        error['codigo'] = isBlank(values.codigo)
        erros = erros || isBlank(values.codigo)
        error['data'] = isBlank(values.data)
        erros = erros || isBlank(values.data)
        error['cep'] = isBlank(values.cep)
        erros = erros || isBlank(values.cep)
        error['endereco'] = isBlank(values.endereco)
        erros = erros || isBlank(values.endereco)
        error['cliente.nome'] = isBlank(values.cliente.nome)
        erros = erros || isBlank(values.cliente.nome)
        error['cliente.cep'] = isBlank(values.cliente.cep)
        erros = erros || isBlank(values.cliente.cep)
        error['cliente.endereco'] = isBlank(values.cliente.endereco)
        erros = erros || isBlank(values.cliente.endereco)
        error['cliente.numero'] = isBlank(values.cliente.numero)
        erros = erros || isBlank(values.cliente.numero)
        setError(error);
        return erros;
    }

    useEffect(() => {
        if (!id)
            fetch(`${url}/visitas/next`,{
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues({ ...values, ['codigo']: result.code });
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);

    useEffect(() => {
        if (id)
            fetch(`${url}/visitas/${id}`,{
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);

    const classes = useStyles();
    return (
        <Layout>
            {redirecionar ? <Redirect to="/visitas"></Redirect> : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>{id ? 'Editar Visita' : 'Adicionar Visita'}</Title>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>Obra</Title>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required id="codigo" label="Código" fullWidth onChange={handleChange('codigo')} value={values.codigo} error={error['codigo']} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        value={values.data}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField required label="CEP" fullWidth onChange={handleCepChange} value={values.cep} error={error['cep']} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField required label="Endereço" fullWidth onChange={handleChange('endereco')} value={values.endereco} error={error['endereco']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField required label="Número" fullWidth onChange={handleChange('numero')} value={values.numero} error={error['numero']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Complemento" fullWidth onChange={handleChange('complemento')} value={values.complemento} error={error['complemento']} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Acabamento" fullWidth onChange={handleChange('acabamento')} value={values.acabamento} error={error['acabamento']} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Observação" fullWidth onChange={handleChange('observacao')} value={values.observacao} error={error['observacao']} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Title>Cliente</Title>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField required label="Nome" fullWidth onChange={handleChangeCliente('nome')} value={values.cliente.nome} error={error['cliente.nome']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="CPF / CNPJ" fullWidth onChange={handleChangeCliente('cpfCnpj')} value={values.cliente.cpfCnpj} error={error['cliente.cpfCnpj']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Celular" fullWidth onChange={handleChangeCliente('celular')} value={values.cliente.celular} error={error['cliente.celular']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Telefone" fullWidth onChange={handleChangeCliente('telefone')} value={values.cliente.telefone} error={error['cliente.telefone']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField required label="CEP" fullWidth onChange={handleCepClienteChange} value={values.cliente.cep} error={error['cliente.cep']} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField required label="Endereço" fullWidth onChange={handleChangeCliente('endereco')} value={values.cliente.endereco} error={error['cliente.endereco']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField required label="Número" fullWidth onChange={handleChangeCliente('numero')} value={values.cliente.numero} error={error['cliente.numero']} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label="Complemento" fullWidth onChange={handleChangeCliente('complemento')} value={values.cliente.complemento} error={error['cliente.complemento']} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" onMouseDown={handleMouseDownPassword} onClick={save}>
                                    Salvar
                                    </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button href="/visitas" variant="contained" style={{
                                    float: "right"
                                }}>
                                    Voltar
                                    </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}