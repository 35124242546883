import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, Button, Link, Snackbar } from '@material-ui/core';
import Title from './Title';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isBlank } from './validacao'
import { url, getToken } from './_helpers'

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


export default function ProdutoAdd(props) {
    let { id } = useParams();
    const [error, setError] = useState({});
    const [redirecionar, setRedirecionar] = useState(false);
    const [values, setValues] = useState({
        id: 0,
        codigo: '',
        preco: '',
        unidade: 'UNIDADE',
        peso: '',
        largura: '',
        comprimento: '',
        descricao: ''
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const save = () => {
        console.log(values)
        const erros = isErrors()
        console.log(erros)
        if (erros) {
            enqueueSnackbar('Preencha todos os campos obrigatorios', {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            });
        } else {
            console.log(values)
            fetch(`${url}/produtos`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                },
                method: 'post',
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then(
                    (result) => {
                        setRedirecionar(true)
                        enqueueSnackbar('Produto salvo com sucesso', {
                            variant: 'success', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        });
                    },
                    (error) => {
                        enqueueSnackbar('Preencha todos os campos obrigatorios', {
                            variant: 'error', anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            }
                        });
                    }
                )
        }

    }

    function isErrors() {
        let erros = false;
        error['codigo'] = isBlank(values.codigo)
        erros = erros || isBlank(values.codigo)
        error['preco'] = isBlank(values.preco)
        erros = erros || isBlank(values.preco)
        error['descricao'] = isBlank(values.descricao)
        erros = erros || isBlank(values.descricao)
        setError(error);
        return erros;
    }

    const classes = useStyles();

    useEffect(() => {
        if(!id)
        fetch(`${url}/produtos/next`,{
            headers:{
                'Authorization': `Bearer ${encodeURI(getToken().token)}`,
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                        setValues({ ...values, ['codigo']: result.code });
                },
                (error) => {
                    console.log(error)
                }
            )
    }, []);

    useEffect(() => {
        console.log(id)
        if(id)
            fetch(`${url}/produtos/${id}`,{
                headers:{
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setValues(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, []);


    return (
        < Layout >
        {redirecionar?<Redirect to="/produtos"></Redirect>:null}
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Title>{id ? 'Editar Produto' : 'Adicionar Produto'}</Title>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField required id="codigo" label="Código" fullWidth onChange={handleChange('codigo')} value={values.codigo} error={error['codigo']} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="preco" required>Preço</InputLabel>
                                <Input
                                    required
                                    id="preco"
                                    type="number"
                                    value={values.preco}
                                    onChange={handleChange('preco')}
                                    startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel required id="unidade">Unidade de Medida</InputLabel>
                                <Select
                                    labelId="unidade"
                                    id="unidade-select"
                                    value={values.unidade}
                                    onChange={handleChange('unidade')}
                                >
                                    <MenuItem value={'COMPRIMENTO'}>Comprimento</MenuItem>
                                    <MenuItem value={'UNIDADE'}>Unidade</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="peso">Peso</InputLabel>
                                <Input
                                    id="peso"
                                    type="number"
                                    value={values.peso}
                                    onChange={handleChange('peso')}
                                    startAdornment={<InputAdornment position="start">Kg</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="comprimento">Comprimento</InputLabel>
                                <Input
                                    id="comprimento"
                                    type="number"
                                    value={values.comprimento}
                                    onChange={handleChange('comprimento')}
                                    startAdornment={<InputAdornment position="start">M</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                                <InputLabel htmlFor="largura">Largura</InputLabel>
                                <Input
                                    id="largura"
                                    type="number"
                                    value={values.largura}
                                    onChange={handleChange('largura')}
                                    startAdornment={<InputAdornment position="start">M</InputAdornment>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="descricao" variant="filled" label="Descrição" fullWidth onChange={handleChange('descricao')} value={values.descricao} error={error['descricao']} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onMouseDown={handleMouseDownPassword} onClick={save}>
                                Salvar
                                    </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button href="/produtos" variant="contained" style={{
                                float: "right"
                            }}>
                                Voltar
                                    </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        </Layout >
    )
}