import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { url, getToken } from './_helpers'
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image, Canvas } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import { PDFViewer } from '@react-pdf/renderer';
import {formatDate} from './_helpers'

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px',
        height: '800px'
    },
    fixedHeight: {
        height: 240,
    },
}));

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: '15pt',
        width: '100%'
    },
    text: {
        width: "100%",
        backgroundColor: "#f0f0f0",
        color: "#212121"
    }
});

const loja = {
    SAUDE: {
        endereco: 'Rua Francisco Tapajós 149 – Saúde – São Paulo – SP',
        telefone: '(11) 5068-2783',
        email: 'saude@gbforros.com.br'
    },
    VILA_MARIA: {
        endereco: 'Rua Dias da Silva, nº 1131 – Vila Maria – São Paulo – SP',
        telefone: '(11) 2954-3736',
        email: 'vilamaria@gbforros.com.br'
    }
}

const defaultStyle = ReactPDF.Style = {
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',
    paddingTop: '5px',
    paddingBottom: '5px',
    wordWrap: "break-word",
    whiteSpace: "pre-wrap"
};

export default function PdfVisita(props) {
    let { id } = useParams();
    const [error, setError] = useState({});
    const [redirecionar, setRedirecionar] = useState(false);
    const [values, setValues] = useState({
        id: '',
        codigo: '',
        data: new Date(),
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        acabamento: '',
        observacao: '',
        loja: 'SAUDE',
        cliente: {
            nome: '',
            cpfCnpj: '',
            celular: '',
            telefone: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: ''
        }
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (id)
            fetch(`${url}/visitas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${encodeURI(getToken().token)}`,
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setValues(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
    }, []);


    const classes = useStyles();
    return (
        <Layout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <PDFViewer style={{
                            height: '800px'
                        }}>
                            <Document title={`visita-${values.codigo}`}>
                                <Page size="A4" style={styles.page}>
                                    <View style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex"

                                        }}>
                                            <View style={{ ...defaultStyle, width: '30%', border: "1pt solid black", paddingTop: '30pt', textAlign: 'center'}}>
                                                <Image source={require('./gb-logo-site.png')} style={{width: '40%', marginLeft: 40}}/>
                                                <Text>Forros de PVC</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '70%', border: "1pt solid black", textAlign: 'center', }}>
                                                <View style={{ ...defaultStyle, width: '100%', borderBottom: "1pt solid black", fontSize: '16pt' }}>
                                                    <Text>Clanil Comércio de Plasticos LTDA-ME</Text>
                                                </View>
                                                <View style={{ ...defaultStyle, width: '100%', fontSize: '10pt' }}>
                                                    <Text style={{ marginBottom: '5pt' }}>CNPJ: 09.013.810/0001-57 - IE: 149.780.989.110</Text>
                                                    <Text style={{ marginBottom: '5pt' }}>{loja[values.loja].endereco}</Text>
                                                    <Text style={{ marginBottom: '5pt' }}>{`Telefone: ${loja[values.loja].telefone}`}</Text>
                                                    <Text style={{ marginBottom: '5pt' }}>{`Website: https://gbforros.com Email: ${loja[values.loja].email}`}</Text>
                                                </View>
                                                <View style={{ ...defaultStyle, width: '100%', fontSize: '14pt', borderTop: "1pt solid black" }}>
                                                    <Text>{`Visita N.º ${values.codigo} - Data: ${formatDate(values.data)}`}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>

                                            <View style={{ ...defaultStyle, fontSize: '10pt', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt', fontSize: '12pt' }}>Dados do Cliente</Text>
                                                <Text style={{ marginBottom: '5pt' }}>Nome: {values.cliente.nome}</Text>
                                                <Text style={{ marginBottom: '5pt' }}>CEP: {values.cliente.cep}</Text>
                                                <Text style={{ marginBottom: '5pt' }}>Endereço: {values.cliente.endereco}, Número: {values.cliente.numero}</Text>
                                                {!!values.cliente.complemento && <Text style={{ marginBottom: '5pt' }}>Complemento: {values.cliente.complemento}</Text>}
                                                <Text style={{ marginBottom: '5pt' }}>Celular: {values.cliente.celular} | Telefone: {values.cliente.telefone}</Text>
                                                {!!values.cliente.email && <Text style={{ marginBottom: '5pt' }}>Email: {values.cliente.email}</Text>}
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, fontSize: '10pt', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt', fontSize: '12pt' }}>Dados da obra</Text>
                                                <Text style={{ marginBottom: '5pt' }}>CEP: {values.cep}</Text>
                                                <Text style={{ marginBottom: '5pt' }}>Endereço: {values.endereco}, Número: {values.numero}</Text>
                                                {!!values.complemento && <Text style={{ marginBottom: '5pt' }}>Complemento: {values.complemento}</Text>}
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '30%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Acabamento</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '70%', fontSize: '10pt' }}>
                                                <Text style={{ marginBottom: '7pt' }}>:  [ ] U   [ ] Meia-Cana   [ ] Colonial</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '30%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Forro Térmico</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '70%', fontSize: '10pt' }}>
                                                <Text style={{ marginBottom: '7pt' }}>: [ ] Sim   [ ] Não</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '30%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Forro Velho</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '70%', fontSize: '10pt' }}>
                                                <Text style={{ marginBottom: '7pt' }}>: [ ] Sim   [ ] Não</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '30%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Forma Pagamento</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '70%', fontSize: '10pt' }}>
                                                <Text style={{ marginBottom: '7pt' }}>: [ ] Dinheiro   [ ] Cheque   [ ] Cartão   [ ] Boleto</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '20%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Total</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '40%', fontSize: '10pt' }}>
                                                <Text style={{ marginBottom: '7pt' }}>: R$</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '100%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Serviço realizado em:</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '100%', fontSize: '16pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '5pt' }}>Medidas</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>Metragem</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>Ambiente</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>Metragem</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}>Ambiente</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center', borderRight: "1pt solid black" }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center',borderRight: "1pt solid black"  }}>
                                                <Text style={{ marginBottom: '2pt' }}>X</Text>
                                            </View>
                                            <View style={{ ...defaultStyle, width: '25%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'center' }}>
                                                <Text style={{ marginBottom: '2pt' }}> </Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "stretch",
                                            width: "100%",
                                            height: "auto",
                                            display: "flex",
                                            border: "1pt solid black"
                                        }}>
                                            <View style={{ ...defaultStyle, width: '100%', fontSize: '12pt', fontWeight: '700', paddingLeft: '15px', textAlign: 'left', borderRight: "1pt solid black" }}>
                                                <Text>Observação:{values.observacao}</Text>
                                            </View>
                                        </View>
                                    </View>



                                </Page>
                            </Document>
                        </PDFViewer>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}