import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { TableCell, TableRow, Table, TableBody, IconButton, TablePagination, Tooltip, TextField } from '@material-ui/core';
import Title from './Title';
import { url, stableSort, SortTableHead, getComparator, getToken, Role, getSearchAll } from './_helpers'
import EditIcon from '@material-ui/icons/Edit';

export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const headCells = [
    { id: 'nome', label: 'Nome' },
    { id: 'login', label: 'Login' },
    { id: 'perfil', label: 'Perfil' },
    { id: 'loja', label: 'Loja' },
    { id: 'opcoes', label: 'Opções' },
];

export default function Usuarios() {
    const classes = useStyles();
    const [produtos, setProdutos] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [orderBy, setOrderBy] = React.useState('nome');
    const [order, setOrder] = React.useState('asc');

    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        const token = encodeURI(getToken().token)
        fetch(`${url}/usuario`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => res.json())
            .then(
                (result) => {
                    if (!result.error)
                        setProdutos(result)
                },
                (error) => {
                    console.log(error)
                }
            )
    }, []);
    return (<Layout>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Title>Usuários</Title>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required label="Buscar" fullWidth onChange={handleSearch} value={search} />
                        </Grid>
                        <Grid item xs={2} style={{
                            textAlign: "right"
                        }}>
                            <IconButton href="/usuarios/add">
                                <PersonAddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Table size="small">
                        <SortTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={produtos.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(getSearchAll(produtos, ['id', 'nome', 'login', 'perfil', 'loja'], search), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((produto) => (
                                <TableRow key={produto.id} style={{ height: 53 }}>
                                    <TableCell>{produto.nome}</TableCell>
                                    <TableCell>{produto.login}</TableCell>
                                    <TableCell>{produto.perfil === Role.ADMINISTRADOR ? 'Administrador' : 'Usuário'}</TableCell>
                                    <TableCell>{produto.loja === 'SAUDE' ? 'Saúde' : 'Vila Maria'}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Editar">
                                            <IconButton href={`/usuarios/edit/${produto.id}`}>
                                                <EditIcon></EditIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={produtos.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Produtos por pagina"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? `${count}` : `more than ${to}`}`}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    </Layout>)
}