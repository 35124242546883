import React from 'react';
import clsx from 'clsx';
import Layout from './Layout';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BalcaoPendente from './BalcaoPendente';
import { makeStyles } from '@material-ui/core/styles';
import Liquidados from './Liquidados';
import Relatorio from './Relatorio'


export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (<React.Fragment>
        <Layout>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper className={fixedHeightPaper}>
                        <Relatorio></Relatorio>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <BalcaoPendente url={'balcao'} loja={'SAUDE'} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <BalcaoPendente url={'balcao'} loja={'VILA_MARIA'} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <BalcaoPendente url={'venda'} loja={'SAUDE'} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <BalcaoPendente url={'venda'} loja={'VILA_MARIA'} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Liquidados url={'venda'} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Liquidados url={'balcao'} />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    </React.Fragment>)
}