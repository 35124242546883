import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { TextField, FormControl, InputLabel, Input, InputAdornment, Select, MenuItem, Button, Divider, Tooltip, IconButton } from '@material-ui/core';
import { url, getToken, formatDate } from './_helpers'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import PrintIcon from '@material-ui/icons/Print';
import { formatDateURL} from './_helpers'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Relatorio(props) {
  const classes = useStyles();
  const [total, setTotal] = useState(0.0);
  const curr = new Date(); // get current date
  const lastday = new Date(curr).toUTCString();
  const [values, setValues] = useState({
    dataInicio: new Date(),
    dataFim: new Date()
  })

  const handleDateInicioChange = (date) => {
    setValues({ ...values, dataInicio: date });
  };

  const handleDateFimChange = (date) => {
    setValues({ ...values, dataFim: date });
  };
  return (
    <React.Fragment>
      <Title>Relatório</Title>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data de inicio"
                  value={values.dataInicio}
                  onChange={handleDateInicioChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Data de fim"
                  margin="normal"
                  value={values.dataFim}
                  onChange={handleDateFimChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} md={3}>
              <Tooltip title="Imprimir">
                <IconButton href={`/relatorio/pdf/${formatDateURL(values.dataInicio)}/${formatDateURL(values.dataFim)}`}>
                  <PrintIcon></PrintIcon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}