import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, Table, TableBody, TablePagination, TextField, Tooltip, IconButton } from '@material-ui/core';
import Title from './Title';
import PrintIcon from '@material-ui/icons/Print';
import { url, stableSort, SortTableHead, getComparator, formatDate, getToken, getSearchAll } from './_helpers'
import { useSnackbar } from 'notistack';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const headCells = [
  { id: 'codigo', label: 'Código' },
  { id: 'cliente', label: 'Cliente' },
  { id: 'loja', label: 'Loja' },
  { id: 'dataBaixa', label: 'Data Baixa' },
  { id: 'total', label: 'Valor (R$)' },
  { id: 'situacao', label: 'Situação' },
  { id: 'opcoes', label: 'Opções' }
];

export default function Liquidados(props) {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [orderBy, setOrderBy] = React.useState('codigo');
  const [order, setOrder] = React.useState('asc');

  const [search, setSearch] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
};


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function listar() {
    fetch(`${url}/${props.url}/status/LIQUIDADOS`, {
      headers: {
        'Authorization': `Bearer ${encodeURI(getToken().token)}`,
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result)
        },
        (error) => {
          console.log(error)
        }
      )
  }

  const extornar = (id) => {
    const token = encodeURI(getToken().token)
    fetch(`${url}/${props.url}/extornar/${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        method: 'post',
    }).then(res => res.json()).then((result) => { 
            if (result.error) {
                enqueueSnackbar(`Erro ao extornar item`, {
                    variant: 'error', anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }
                });
            } else {
                enqueueSnackbar('Item extornado com sucesso', {
                    variant: 'success', anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }
                });
                listar();
            }
        },
            (error) => { console.log(error)})
}

  useEffect(() => {
    listar()
  }, []);


  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Title>{props.url === 'balcao' ? 'Liquidados produtos' : 'Liquidados instalados'}</Title>
        </Grid>
        <Grid item xs={6}>
          <TextField required label="Buscar" fullWidth onChange={handleSearch} value={search} />
        </Grid>
      </Grid>
      <Table size="small">
        <SortTableHead
          classes={classes}
          check={false}
          order={order}
          orderBy={orderBy}
          rowCount={items.length}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <TableBody>
          {stableSort(getSearchAll(items, ['codigo', 'loja', 'total', 'situacaoVenda', 'dataBaixa', 'nome'], search), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
            <TableRow key={item.id} style={{ height: 53 }}>
              <TableCell>{item.codigo}</TableCell>
              <TableCell>{item.cliente.nome}</TableCell>
              <TableCell>{item.loja === 'SAUDE' ? 'Saúde' : 'Vila Maria'}</TableCell>
              <TableCell>{!!item.dataBaixa && formatDate(item.dataBaixa)}</TableCell>
              <TableCell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}</TableCell>
              <TableCell>{item.statusVenda}</TableCell>
              <TableCell >
                <Tooltip title="Imprimir">
                  <IconButton href={`/${props.url}/pdf/${item.id}`}>
                    <PrintIcon></PrintIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Extornar">
                  <IconButton onClick={() =>{
                    extornar(item.id)
                  }} onMouseDown={handleMouseDownPassword}>
                    <MoneyOffIcon/>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={items.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Pedidos por pagina"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? `${count}` : `more than ${to}`}`}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Table>
    </>
  )
}