import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { TableCell, TableRow, TextField, Table, TableBody, IconButton, TablePagination, Tooltip } from '@material-ui/core';
import Title from './Title';
import { url, stableSort, SortTableHead,getComparator, formatDate , getToken,getSearchAll} from './_helpers'
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';


export const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      }
}));

const headCells = [
    { id: 'codigo', label: 'Código' },
    { id: 'data', label: 'Data' },
    { id: 'endereco', label: 'Endereço' },
    { id: 'cliente', label: 'Cliente' },
    { id: 'opcoes', label: 'Opções' },
];

export default function Visitas() {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [orderBy, setOrderBy] = React.useState('codigo');
    const [order, setOrder] = React.useState('asc');
    
    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    function list(){
        fetch(`${url}/visitas`,{
            headers: {
                'Authorization': `Bearer ${encodeURI(getToken().token)}`,
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    if(result.error){
                        setItems([])
                    }else{
                        setItems(result)
                    }
                },
                (error) => {
                    console.log(error)
                    setItems([])
                }
            )
    }

    useEffect(() => {
        list()
    }, []);



    return (<Layout>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Title>Visitas</Title>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required label="Buscar" fullWidth onChange={handleSearch} value={search} />
                        </Grid>
                        <Grid item xs={2} style={{
                            textAlign: "right"
                        }}>
                            <Tooltip title="Novo">
                            <IconButton href="/visita/add">
                                <AddCircleOutlineIcon />
                            </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Table size="small">
                        <SortTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={items.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(getSearchAll(items, ['codigo', 'data', 'numero', 'endereco', 'nome'], search), getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow key={item.id} style={{ height: 53 }}>
                                    <TableCell>{item.codigo}</TableCell>
                                    <TableCell>{formatDate(item.data)}</TableCell>
                                    <TableCell>{`${item.endereco}, ${item.numero}`}</TableCell>
                                    <TableCell>{item.cliente.nome}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Realizar serviço">
                                            <IconButton href={`/venda/add/${item.id}`}>
                                                <AddShoppingCartIcon></AddShoppingCartIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                        <IconButton href={`/visita/edit/${item.id}`}>
                                            <EditIcon></EditIcon>
                                        </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Imprimir">
                                        <IconButton href={`/visita/pdf/${item.id}`}>
                                            <PrintIcon></PrintIcon>
                                        </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={items.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage="Visitas por pagina"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? `${count}` : `more than ${to}`}`}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    </Layout>)
}